import React, {useEffect} from 'react';
// import {DrinkData} from '../data/DrinkData.js';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
// import {navigate} from "gatsby"
import Layout from "../components/layout";
import Snackbar from '@material-ui/core/Snackbar';
import firebaseAlt from '@firebase/app';
// import Alert from '@material-ui/lab/Alert';

import getFirebase from '../utils/firebase';
// import {navigate} from "gatsby"

import MenuAppBar from '../components/AppBar'

const firebase = getFirebase();

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '',
    minHeight: '100vh',
    padding: '50px 30px 30px'
  },
  infoItem: {
    backgroundColor: '',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '23px',
    [
      theme
        .breakpoints
        .up('md')
    ]: {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  },
  header: {
    backgroundColor: '',
    marginBottom: '-10px',
    // [
    //   theme
    //     .breakpoints
    //     .down('sm')
    // ]: {
    //   marginBottom: '0px',
    //   paddingTop: '40px'
  },
  mainTitle: {
    fontSize: '70px',
    fontWeight: 800,
    [
      theme
        .breakpoints
        .down('md')
    ]: {
      fontSize: '60px',
      paddingLeft: '0px'
    },
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '60px',
      fontWeight: 800,
      textAlign: 'center',
      paddingTop: '40px'
    }
  },
  mainDetails: {
    fontSize: '35',
    fontWeight: 700,
    padding: '0px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      padding: '10px',
      paddingTop: '0px',
      textAlign: 'center'
    }
  },
  drinkImage: {
    margin: 0,
    objectFit: 'cover',
    width: '280px',
    height: '280px',
    padding: '25px',
    borderRadius: '50%'
  },
  itemTitle: {
    fontSize: 38,
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '1.5',
    marginBottom: '10px'
  },
  itemDetails: {
    fontSize: 20,
    fontWeight: 400,
    textAlign: 'center',
    lineHeight: '1.2'
  },
  ingredients: {
    fontSize: 20,
    fontWeight: 400
  },
  buttonContainer: {
    backgroundColor: '',
    padding: '40px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      padding: '10px',
      paddingBottom: '60px'
    }
  },
  infoItemContainer: {
    backgroundColor: '',
    // marginLeft: '-18px',
    // marginRight: '-3px',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      marginLeft: '0px',
      marginRight: '0px'
    }
  },
  ShareDialog: {
    padding: '30px 60px',
    paddingBottom: '35px',
    textAlign: 'center',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '25px 30px 20px'
    }
  },
  link: {
    fontSize: 35,
    fontWeight: 600,
    paddingBottom: '3px',
    textAlign: 'center',
    lineHeight: '1.2',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: 25
    }
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props}/>;
});

function ShareDialog(props) {
  const classes = useStyles();
  const {onClose, selectedValue, open} = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  // <Button onClick={props.copyLink} size="large" variant='contained' color="secondary">Copy Link</Button>

  return (<Dialog onClose={handleClose} aria-labelledby="share-dialog" open={open} TransitionComponent={Transition}>
    <div className={classes.ShareDialog}>
      <DialogTitle style={{
          color: 'grey'
        }}>Share with a Friend</DialogTitle>
      <h2 className={classes.link}>{`drinkmix.app${props.activeDrink.link}`}</h2>
    </div>
  </Dialog>);
}

function DrinkPage(props) {
  const classes = useStyles();
  let activeDrink = props.drinkData;

  const pageLink = `https://drinkmix.app${activeDrink.link}`

  function copyLink(e) {
    navigator
      .clipboard
      .writeText(pageLink);
    handleSnackOpen()
  }

  const handleSnackOpen = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = value => {
    setSnackOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [activeUser, setActiveUser] = React.useState(null);
  // const [newFavourites, setNewFavourites] = React.useState([]);
  const [isFavourite, setIsFavourite] = React.useState(null);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = value => {
    setOpen(false);
  };

  // const handleClick = () => {
  //   navigate('/')
  // };

  useEffect(() => {
    if (!firebase)
      return;
    if (loggedIn)
      return;
    firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          // alert('User is signed in')
          // setIsSignedIn(!!user);
          setActiveUser(user);
          const db = firebase.firestore();
          let docRef = db
            .collection("users")
            .doc(user.uid)
          docRef
            .get()
            .then(function(doc) {
              if (doc.exists) {
                if (doc.data()['favourites'].includes(activeDrink.id)) {
                  setIsFavourite(true)
                  setLoggedIn(true)
                  // console.log(doc.data()['favourites'].includes(activeDrink.id))
                } else {
                  setIsFavourite(false)
                  setLoggedIn(true)
                  // console.log(doc.data()['favourites'].includes(activeDrink.id))
                }
                // console.log("Document data:", doc.data());
              }
            })
            .catch(function(error) {
              console.log("Error getting document:", error);
            });
        } else {
          // navigate('/')
        }
      });
  });

  function toggleFavourites() {
    // const db = firebase.firestore();
    // db
    //   .collection("users")
    //   .doc(props.activeUser.uid)
    //   .doc("favourites")
    //   .set(null);

    // console.log(activeUser.uid)
    const db = firebase.firestore();
    var userRef = db
      .collection("users")
      .doc(activeUser.uid);
    var drinkRef = db
      .collection("drinks")
      .doc('classics');

    // let likedBy = drinksRef
    //   .get()
    //   .then(function(doc) {
    //     if (doc.exists) {
    //       setDrinks(doc.data())
    //       setIsLoaded(true);
    //     } else {
    //       navigate('/home')
    //     }
    //   })
    //   .catch(function(error) {
    //     console.log("Error getting document:", error);
    //   });

    if (!isFavourite) {
      // Add a new favourite to the "favourites" array field.
      userRef.update({
        favourites: firebaseAlt
          .firestore
          .FieldValue
          .arrayUnion(activeDrink.id)
      });
      // let property = activeDrink.id + '.likedBy'
      drinkRef.update({
        [activeDrink.id + '.likedBy']: firebaseAlt
          .firestore
          .FieldValue
          .arrayUnion(activeUser.uid)
      });
      setIsFavourite(true)
    } else if (isFavourite) {
      // Remove a favourite from the "favourites" array field.
      userRef.update({
        favourites: firebaseAlt
          .firestore
          .FieldValue
          .arrayRemove(activeDrink.id)
      });
      drinkRef.update({
        [activeDrink.id + '.likedBy']: firebaseAlt
          .firestore
          .FieldValue
          .arrayRemove(activeUser.uid)
      });
      setIsFavourite(false)
    }

    // if (!newFavourites.includes(activeDrink.id)) {
    //   newFavourites.push(activeDrink.id)
    //   setIsFavourite(false)
    //   console.log(newFavourites)
    // } else if (newFavourites.includes(activeDrink.id)) {
    //   const index = newFavourites.indexOf(activeDrink.id);
    //   if (index > -1) {
    //     newFavourites.splice(index, 1);
    //   }
    //   setIsFavourite(false)
    //   console.log(newFavourites)
    // };
    // db
    //   .collection("users")
    //   .doc(activeUser.uid)
    //   .set({favourites: newFavourites});
  }

  const formattedIngredients = activeDrink
    .ingredients
    .map((ingredientObject) => (<FormControlLabel className={classes.ingredients} control={<Checkbox />} label={`${ingredientObject.amount} ${ingredientObject.name}`}/>))

  function goBack() {
    if (typeof window !== `undefined`) {
      window
        .history
        .back();
    }
  }

  return (<Layout>
    <MenuAppBar/>
    <Fade in="in">
      <div>
        <Grid container="container" direction='column' justify='center' className={classes.root}>
          <Grid item="item" className={classes.header}>
            <Grid container="container" justify='center'>
              <Grid container="container" xs={12} md={8} direction='column' justify='center' style={{
                  backgroundColor: ''
                }}>
                <Grid>
                  <h1 className={classes.mainTitle}>The {activeDrink.name}</h1>
                </Grid>
                <Grid>
                  <h2 className={classes.mainDetails}>{activeDrink.description}</h2>
                </Grid>
              </Grid>
              <Grid item="item">
                <img className={classes.drinkImage} src={activeDrink.image} alt={activeDrink.name}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item="item">
            <Grid container="container" justify='space-evenly' className={classes.infoItemContainer}>
              <Grid item="item" sm={12} md={4} justify='center' className={classes.infoItem}>
                <h1 className={classes.itemTitle}>About</h1>
                <h2 className={classes.itemDetails}>{activeDrink.details}</h2>
              </Grid>
              <Grid item="item" className={classes.infoItem} justify='center'>
                <h1 className={classes.itemTitle}>Ingredients</h1>
                <Grid container="container" direction='column' alignItems='center'>
                  {formattedIngredients}
                </Grid>
              </Grid>
              <Grid item="item" sm={12} md={4} justify='center' className={classes.infoItem}>
                <h1 className={classes.itemTitle}>Preparation</h1>
                <h2 className={classes.itemDetails}>{activeDrink.preparation}</h2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item="item">
            <Grid container="container" justify='center' wrap='wrap-reverse' spacing={2} className={classes.buttonContainer}>
              <Grid item="item">
                <Button onClick={goBack} size="large" variant='outlined' color="secondary">Back to Drinks</Button>
              </Grid>
              <Grid item="item">
                {
                  (loggedIn && <Button onClick={toggleFavourites} size="large" variant={isFavourite
                      ? 'outlined'
                      : 'contained'} color="secondary">{
                      isFavourite
                        ? 'Recipe Saved'
                        : 'Add to Favourites'
                    }</Button>)
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fade>
    <ShareDialog open={open} onClose={handleClose} copyLink={copyLink} activeDrink={activeDrink}/>
    <Snackbar open={snackOpen} autoHideDuration={1000} message='Link successfully copied!' onClose={handleSnackClose}></Snackbar>
  </Layout>)
}

export default DrinkPage
