import React from "react"
import DrinkPage from '../components/DrinkPage'
import SEO from "../components/seo"
import {DrinkData} from '../data/DrinkData.js';

export default function DrinkPageInstance({location}) {
  return (<div>
    <SEO title={location.state ? 'The ' + location.state.drinkData.name : 'The Mojito'}/>
    <DrinkPage drinkData={location.state ? location.state.drinkData : DrinkData[0]}/>
  </div>)
}
